'use client'
import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { Transaction } from '@tiptap/pm/state'
import { Editor, EditorOptions, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { ReactNode, createContext, useContext, useEffect } from 'react'

import styles from './RichText.module.css'

export type RichTextProviderProps = Partial<EditorOptions> & {
  defaultValue?: string
  children?: ReactNode
  setExerciseSuggestionsContainer?: (element: HTMLElement | null) => void
}

type RichTextContextResult = {
  editor: Editor | null
}

const RichTextContext = createContext({} as RichTextContextResult)

export const useRichTextContent = () => useContext(RichTextContext)

export type OnUpdateProps = {
  editor: Editor
  transaction: Transaction
}

// We can add Tiptap's Mention extension on future, but for now it doesn't worth the > 2 MB weight of dependencies
const CustomHighlight = Highlight.extend({
  addKeyboardShortcuts() {
    return {
      'Shift-2': () => this.editor.commands.toggleHighlight(),
      // Remove highlight when press space
      Space: () =>
        this.editor.isActive('highlight')
          ? this.editor.commands.toggleHighlight()
          : false,
    }
  },
})

const RichTextProvider = ({
  children,
  defaultValue,
  extensions,
  setExerciseSuggestionsContainer,
  ...props
}: RichTextProviderProps) => {
  // Filter props to ensure only valid values are passed
  const validProps = Object.entries(props).reduce<Record<string, any>>(
    (acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value
      } else {
        console.warn(`Invalid prop for editor: ${key}`)
      }
      return acc
    },
    {}
  )

  const editor = useEditor({
    ...validProps, // Spread validated props
    extensions: [
      StarterKit.configure({
        bulletList: { keepMarks: true, keepAttributes: false },
        orderedList: { keepMarks: true, keepAttributes: false },
      }),
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      Underline,
      CustomHighlight.configure({
        HTMLAttributes: {
          class: styles.highlight,
        },
      }),
      ...(extensions ?? []),
    ],
    content: defaultValue,
  })

  useEffect(() => {
    if (defaultValue && defaultValue !== editor?.getHTML()) {
      // Update editor content if defaultValue changes
      Promise.resolve().then(() => {
        editor?.commands.setContent(defaultValue)
      })
    }
  }, [defaultValue, editor])

  return (
    <RichTextContext.Provider
      value={{
        editor,
      }}
    >
      {children}
    </RichTextContext.Provider>
  )
}

export const RichTextConsumer = RichTextContext.Consumer

export default RichTextProvider
